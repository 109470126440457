import React from 'react'
import Header from './Header';
import { LanguageConsumer } from './lib/LanguageContext'

export default function LoggedOut() {

    return (
        <>
            <Header disableNav={true} disableLogout={true} />
            <LanguageConsumer>
                {({ polyglot }) => (
                    <div id='error' className="main">
                        <h1>{polyglot.t('logoutInfo.header')}</h1>
                            <a style={{'fontSize': '19px'}}href="/">{polyglot.t('logoutInfo.return')}</a>
                    </div>
                )}
            </LanguageConsumer>
        </>
    )
}