import React from 'react';
import Polyglot from 'node-polyglot';
import LocaleEn from '../i18n/locale-en';
import LocaleEl from '../i18n/locale-el';

const POLYGLOT = {
    el: new Polyglot({ phrases: LocaleEl.phrases, locale: 'el' }),
    en: new Polyglot({ phrases: LocaleEn.phrases, locale: 'en' })
}

const LanguageContext = React.createContext({
    polyglot: POLYGLOT['el']
})

class LanguageProvider extends React.Component {

    state = {
        lang: 'el'
    }

    handleLanguageChange = (lang) => {
        if (lang === 'en' || lang === 'el') {
            this.setState({ lang })
        } else {
            this.setState({ lang: 'el' })
        }
    }

    render = () => (
        <LanguageContext.Provider value={{ polyglot: POLYGLOT[this.state.lang] }}>
            {this.props.children}
        </LanguageContext.Provider>
    )

}

const LanguageConsumer = LanguageContext.Consumer;

export { LanguageProvider, LanguageConsumer, LanguageContext }
