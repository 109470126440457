import React from 'react';
import './App.css';
import Home from './Home';
import Clients from './Clients';
import Data from './Data';
import Footer from './Footer';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Redirect from "./components/Redirect";
import { AuthProvider } from './lib/AuthContext'
import ClientRedirect from './ClientRedirect';
import ErrorPage from './ErrorPage';
import LoggedOut from './LoggedOut';

library.add(fas)

function App() {

  const NotFound = () => {
    return (<Redirect to='/'/>)
  }

  return (
    <React.Fragment>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<AuthProvider><Home/></AuthProvider>}/>
            <Route path="/consents" element={<AuthProvider><Clients/></AuthProvider>}/>
            <Route path="/mydata/report" element={<AuthProvider><Data report={true}/></AuthProvider>}/>
            <Route path="/mydata/report/redirect" element={<ClientRedirect/>}/>
            <Route path="/mydata" element={<AuthProvider><Data report={false}/></AuthProvider>}/>
            <Route path="/errorpage" element={<AuthProvider><ErrorPage/></AuthProvider>}/>
            <Route path="/logged-out" element={<AuthProvider><LoggedOut/></AuthProvider>}/>
            <Route path="*" element={<NotFound/>} />
          </Routes>
          <Footer/>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
