import React from 'react';
import { LanguageConsumer } from './lib/LanguageContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Login.css';
import { Urls } from './index'

export default(props) => {

  const baseUrl = `${Urls.authUrl}/login`
  const next = props.next ? props.next : 'mydata'
  const locationUrl = `${baseUrl}?next=${next}`

  const login = () => {
    window.location = locationUrl
  }

  return (
    <LanguageConsumer>
      {({ polyglot }) => (
        <div id='login'>
          <p>Εάν επιθυμείτε να δείτε τα στοιχεία σας και το ιστορικό των εξουσιοδοτήσεων αρκεί να συνδεθείτε στην υπηρεσία.</p>
          <button onClick={ login }>{ polyglot.t('login.login') } <FontAwesomeIcon icon='caret-square-right'/></button>
        </div>
      )}
    </LanguageConsumer>
  )
}

