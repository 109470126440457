import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Redirect(props) {
    // React router v6 removed redirect components
    // We replicate the functionality with useNavigate

    let navigate = useNavigate();
    useEffect(() => {
        navigate(props.to);
    });
    return null;
}