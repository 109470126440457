import React, { useEffect, useState } from 'react'
import Header from './Header';
import { LanguageConsumer } from './lib/LanguageContext'
import { useSearchParams } from 'react-router-dom';
import { phrases } from './i18n/locale-el';

const availableErrors = Object.keys(phrases.errorPage);

export default function ErrorPage() {

    const [ errorType, setErrorType ] = useState('general')

    const [ params ] = useSearchParams();

    useEffect(() => {
        if (params && params.get('type') && availableErrors.includes(params.get('type'))) {
            setErrorType(params.get('type'));
        }
    }, [ params ]);

    return (
        <>
            <Header disableNav={true} disableLogout={true} />
            <LanguageConsumer>
                {({ polyglot }) => (
                    <div id='error' className="main">
                        <h1>{polyglot.t(`errorPage.${errorType}.heading`)}</h1>
                        <div className='box'>
                            <p style={{'fontSize': '18px'}}>{polyglot.t(`errorPage.${errorType}.text`)}</p>
                        </div>
                    </div>
                )}
            </LanguageConsumer>
        </>
    )
}