import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { formatScopes} from '../lib/util'

import './CollapsePill.css';

const CollapsePill = (props) => {
    const [open, setOpen] = useState(false)

    return (

        <div className={`pill ${open ? 'opened' : 'closed'} ${props.history ? 'colored' : ''}`}>
            <div className='pill-header' onClick={() => setOpen(!open)}>
                <span className='pill-header-text'>
                    { props.header }
                </span>
                <span className='pill-header-btn'>
                    <FontAwesomeIcon icon={`chevron-${open ? 'up' : 'down'}`}></FontAwesomeIcon>
                </span>
                <div className='pill-subheader-text'>
                    <div className='pill-org'>
                        { props.org }
                    </div>
                    <div className='pill-last-date'>
                        Τελευταία Άδεια:&nbsp; 
                        <strong>{ moment.unix(props.lastDate).format("DD/MM/YYYY") }</strong> { moment.unix(props.lastDate).format("hh:mm A") }
                    </div>
                </div>
                <div className='pill-last-scope'>
                    {props.polyglot.t('clients.scopes')}:{' '}
                    {formatScopes(props.lastScope.split(" ").map(scope => props.polyglot.t('clients.scopeNames.' + scope)))}
                </div>
            </div>
            <div className='pill-content'>
                { props.children }
            </div>
        </div>
    )
}

CollapsePill.Action = ({children}) => (
    <div className='action-area'>
        { children }
    </div>
)

CollapsePill.DataTable = ({children}) => (
    <div className="table">
            { children }
    </div>
)

CollapsePill.Pair = ({field, value}) => (
    <tr>
        <td>{field}</td>
        <td>{value}</td>
    </tr>
)

export default CollapsePill
