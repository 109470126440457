import React, { useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom"
import { LanguageConsumer } from "./lib/LanguageContext"
import { AuthContext, AuthConsumer } from "./lib/AuthContext"
import { Urls } from "./index"

const NavLink = props => <Link getProps={({ isCurrent }) => isCurrent ? { className: "current" } : null} {...props} />

export default (props) => {
    const disableNav = props.disableNav ? props.disableNav : false
    const disableLogout = props.disableLogout ? props.disableLogout : false
    const authContext = useContext(AuthContext)
    const navRef = useRef(null);
    const openButtonRef = useRef(null);
    const [mobileNavBar, setMobileNavBar] = useState(false);

    if (process.env.REACT_APP_MODE === 'consent') {
        document.title = 'Gov.gr - Υπηρεσία Διάθεσης Στοιχείων';
    }

    const toggleNavBar = () => {
        setMobileNavBar(!mobileNavBar)
    }

    const closeNavBar = () => setMobileNavBar(false)

    const handleClickOutside = (event) => {
        if (navRef && openButtonRef && navRef.current && openButtonRef.current && !navRef.current.contains(event.target) && !openButtonRef.current.contains(event.target)) {
            closeNavBar()
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LanguageConsumer>
            {({ polyglot }) => (
                <AuthConsumer>
                    {({ loggedIn, firstname, lastname, taxid }) => (
                        <div id="Header">
                            <div id="logo-area">
                                <Link to="/">
                                    {process.env.REACT_APP_MODE === 'consent' ?
                                        <img id='Logo' src='/logo_govgr.svg' alt='logo_govgr.svg' />
                                        :
                                        <img id="Logo" src='/logo_KYC_white.svg' alt='logo_KYC_white.svg' />
                                    }
                                    {!process.env.REACT_APP_MODE === 'consent' && (<span id='title'>Know Your Customer</span>)}
                                </Link>
                                {process.env.REACT_APP_MODE === 'consent' ? (
                                    <div className="subtitle">Υπηρεσία Διάθεσης Στοιχείων μέσω της Ενιαίας Ψηφιακής Πύλης της Δημόσιας Διοίκησης</div>
                                ) : (
                                    <div className="subtitle">Επικοινωνία του ιδιωτικού και δημόσιου τομέα με κέντρο τον πολίτη</div>
                                )}
                            </div>
                            <div className='right-area'>
                                {!process.env.REACT_APP_MODE === 'consent' && <img id='govgr-logo' src='/logo_govgr.svg' alt='logo_govgr.svg' />}
                                {loggedIn && (
                                    <>
                                        {!props.disableNav && (
                                            <>
                                                <span className='mobile'>
                                                    <button ref={openButtonRef} className='open-menu' onClick={toggleNavBar}>
                                                        <FontAwesomeIcon icon='bars' />
                                                    </button>
                                                </span>
                                            </>
                                        )}

                                        <div className="nav-area" >
                                            <span className='nav-links'>
                                                {!props.disableLogout && (
                                                    <a href={`${Urls.authUrl}/logout`} className='logout-link'> Αποσύνδεση</a>
                                                )}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                            {(loggedIn && (<div id="NavBar">
                                <div className='header-border'></div>
                                {!(props.disableLogout && props.disableNav) && (
                                    <span ref={navRef} className={`content`}>
                                        <div className="nav-area">
                                            <span className='nav-links'>
                                                {!disableNav && (
                                                    <>
                                                        <NavLink to="/mydata" onClick={closeNavBar} >{polyglot.t('nav.myData')}</NavLink>
                                                        <NavLink to="/consents" onClick={closeNavBar} >{polyglot.t('nav.banks')}</NavLink>
                                                    </>
                                                )}</span>
                                            <hr />
                                            {!props.disableLogout && (
                                                <span className='nav-user'>
                                                    <span>
                                                        <span className='nav-user-name'>{firstname}{' '}{lastname}{' '}<span className='line'>|</span>{' '}</span>
                                                        <span>ΑΦΜ: {taxid}</span>
                                                    </span>
                                                    <a href={`${Urls.authUrl}/logout`} className='mobile-logout'>
                                                        <FontAwesomeIcon icon={'sign-out-alt'} />
                                                    </a>
                                                </span>
                                            )}
                                        </div>
                                    </span>
                                )}
                            </div>))}
                            {(!loggedIn && (<div id="NavBarPlaceholder"><div className='header-border'></div></div>))}
                        </div>)}
                </AuthConsumer>)}
        </LanguageConsumer>
    )
}
