import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageConsumer } from '../lib/LanguageContext';
import SkeletonLoader from './SkeletonLoader';

const imageMapping = {
  elas: 'logo_elas.png',
  ggps: 'logo_ggpsd.png',
  aadeIncome: 'logo_aade.png',
  aadeSelfEmployed: 'logo_aade.png',
  publicSector: 'apografi.png',
  ergani: 'logo_ergani.png',
  aadeTaxidInfo: 'logo_aade.png',
  army: 'logo_army.png',
  hcg: 'logo_hcg.png',
  fire: 'logo_fire.png',
  elasOff: 'logo_elas.png',
  elasPassport: 'logo_elas.png',
  aadefamilyStatus: 'logo_aade.png',
  emigPermit: 'logo_yma.png',
  emigPassport: 'logo_yma.png',
  dypa: 'logo_dypa.png',
  efka: 'logo_efka.png',
  edyte: 'logo_edyte.png',
  ypes: 'logo_ypes.png',
  minedu: 'logo_minedu.png',
  aadeElectricitySupply: 'logo_aade.png',
  other: 'other.png'
}


export const MultiValue = (props) => (
  <LanguageConsumer>
    {({ polyglot }) => (
      <div className='row'>
        <React.Fragment>
          <h4>{ props.id }</h4>
          <div className='content'>
            { props.children }
          </div>
        </React.Fragment>
      </div>
      )}
   </LanguageConsumer>
)

export const ImageValue = (props) => (
  <LanguageConsumer>
    {({ polyglot }) => (
      <div className='row'>
        <span className='key'>
          { props.id }:
        </span>
        <span className='value'>
          {props.value ? 
            <img src={`data:image/jpeg;base64, ${props.value}`} alt='' height="180"/>
          :
            <span>-</span>
          }
        </span>
    </div>)}
  </LanguageConsumer>
)

const dateHelper = (dateVal) => {
  try {
    const year = dateVal.substring(0, 4)
    const month = dateVal.substring(4, 6)
    const day = dateVal.substring(6, 8)
    if (day && month && year) {
      return `${day}/${month}/${year}`
    } else {
      return '-'
    }
  } catch (e) {
    return ""
  }
}

const RenderValue = (props) => {
  let value = props.value
  if (!props.noDash && !value) {
    value = "-"
  }

  return (
  <LanguageConsumer>
      {({ polyglot }) => (
      <div className='row'>
        <span className='key'>
          { props.id }:
        </span>
        <span className='value'>
          { value }
        </span>
      </div>)}
    </LanguageConsumer>
  )
}

export const SingleValue = (props) => {
  return (<RenderValue id={props.id} value={props.value}/>)
}

export const DateValue = (props) => {
  const value = dateHelper(props.value)
  if (props.inline) {
    return (value)
  } else {
    return (<RenderValue id={props.id} value={value}/>)
  }
}

export const MoneyValue = (props) => {
  if (!props.value || props.value === "0") {
    return ""
  } else {
    const locValue = Number(props.value).toLocaleString('el-GR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    return (<RenderValue id={props.id} value={locValue}/>)
  }
}

export const MapValue = (props) => {
  let value = "-"
  if(props.mapping[props.value]) {
    value = props.mapping[props.value]
  }
  return (<RenderValue id={props.id} value={value}/>)

}

export const SeparatedValue = (props) => {
  let value
  try {
    value = props.value.join(", ")
  } catch (e) {
    value = ""
  }
  return (<RenderValue id={props.id} value={value} />)
}

export const ErrorValue = (props) => (
  <React.Fragment>
    { props.critical === 'true' 
        ?
          ( <div className='error-wrapper'> 
              <FontAwesomeIcon icon='exclamation-triangle'/>
              <span>{ props.error }</span> 
            </div> )
        :
          ( <div className='error-wrapper light'> 
              <span>{props.icon && <FontAwesomeIcon icon={props.icon}/>}{ props.error }</span> 
            </div> )
    }
  </React.Fragment>
)

const dataSourceHelper = (polyglot, source) => {
  const long = polyglot.t(`data.sources.${source}.long`);
  const short = polyglot.t(`data.sources.${source}.short`);
  // Check if short does not have a locale
  if (short === '') {
    return long;
  } else {
    return `${long} - ${short}`
  }
}

export const EmptyValue = (props) => (
  <LanguageConsumer>
      {({ polyglot }) => (
        <div>
          <span><strong>{props.title}</strong></span>
          <span> - </span>
          <span>{props.status === '1201' ? polyglot.t('data.noData') : polyglot.t('data.couldNotConnect')} ({polyglot.t(`data.sources.${props.source}.short`)})</span>
        </div>
      )}
      </LanguageConsumer>
)

export const Section = (props) => (
  <LanguageConsumer>
      {({ polyglot }) => (
        <React.Fragment>
          {props.singleSection && (
            <span className='section-source-wrapper'>
              <span className='tooltip' data-tooltip={ dataSourceHelper(polyglot, props.source) }>
                <span className='section-source'>
                  <img id = {`${imageMapping[props.source].substring(0, imageMapping[props.source].indexOf("."))}`} src={`/${imageMapping[props.source]}`} alt='' />
                </span>
              </span>
            </span>
          )}
          <div className='data-section'>
                {!props.singleSection && (
                  <React.Fragment>
                    <h3>{ props.title }</h3>
                    <span className='section-source-wrapper'>
                      <span className='tooltip' data-tooltip={ dataSourceHelper(polyglot, props.source) }>
                        <span className='section-source'>
                          <img id = {`${imageMapping[props.source].substring(0, imageMapping[props.source].indexOf("."))}`} src={`/${imageMapping[props.source]}`} alt='' />
                        </span>
                      </span>
                    </span>
                  </React.Fragment>
                )}

              { 
                function errorHandling(props) {
                  return (
                      () => {
                        switch (props.status) {
                          case '1200':
                          case '1202':
                            return  <div className='section-content'>
                                      {props.children}
                                    </div>
                          case '1201':
                            let errorText = [<div className='error-wrapper'><ErrorValue icon={'info-circle'} critical='false' error={ polyglot.t(`data.noData`) } /></div>]
                            if (props.template1201) {
                              const tpl = (<div className='section-content'>
                                {props.template1201}
                              </div>)
                              errorText.push(tpl)
                            }
                            return errorText
                          default:
                            return  <div className='error-wrapper'><ErrorValue critical='true' error={ polyglot.t(`data.noConnectionAvailable`) }/></div>
                        }
                      })()
                }(props)
              }


                <span className='mobile section-source-wrapper'>
                  <span className='section-source'>
                    Πηγή: { dataSourceHelper(polyglot, props.source) }
                  </span>
                </span>
              </div>
          <hr className="no-margin"/>
        </React.Fragment>)}
    </LanguageConsumer>
)

export const DataCategory = (props) => {
  const [open, setOpen] = useState(props.toggleable ? false : true);

  const toggleOpen = () => {
    if (props.toggleable) {
      setOpen(open => !open);
    }
  }

  return (
    <LanguageConsumer>
      {({ polyglot }) => (
        <React.Fragment>
          <div className={`data-category ${props.toggleable ? 'toggle' : ''} ${open ? 'open' : ''}`} id={props.category}>
            <div onClick={toggleOpen} className='data-category-header'>
              <FontAwesomeIcon className='icon' icon={props.icon} />
              <h2>
                {polyglot.t(`data.${props.category}.title`)}

              </h2>
              {props.toggleable && (
                <span className='toggle-control'>
                  <FontAwesomeIcon icon={`fa-chevron-${open ? 'up' : 'down'}`} />
                </span>
              )}
            </div>

            {open && (
              <>
                {props.display ? (
                  <>
                    {props.children}
                  </>
                ) : (
                  <div className='section-content'>
                    <SkeletonLoader count={8} />
                  </div>
                )}
              </>
            )}
          </div>
        </React.Fragment>
      )}
    </LanguageConsumer>
  )
}

