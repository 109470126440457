import React, { useEffect, useState } from 'react';
import { LanguageConsumer } from './lib/LanguageContext';
import { Urls } from './index'
import './Auth.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Header from './Header';
import { useLocation, useNavigate } from 'react-router-dom';


export default function ClientRedirect() {
    const redirectURL = Urls.authUrl + '/oauth/client-redirect';

    const location = useLocation();
    let navigate = useNavigate();

    const [displayName, setDisplayName] = useState('');

    useEffect(() => {
        async function fetchClientData() {
            try {
                let res = await fetch(Urls.authUrl + '/api-mydata/clientData', { credentials: 'include' });
                const clientData = await res.json();
                if (clientData != {} && clientData.displayName) {
                    setDisplayName(clientData.displayName);
                    window.history.pushState({}, '', '/logged-out');
                    setTimeout(() => {
                        window.location.href = redirectURL;
                    }, 300);
                } else {
                    navigate('/logged-out', {replace: true});
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchClientData();
    }, [location]);

    return (
        <LanguageConsumer>
            {({ polyglot }) => (
                <>
                    <Header disableNav={true} disableLogout={true} />
                    <div className='main' style={{ 'minHeight': '54vh' }}>
                        {displayName && (
                            <>
                                <div id="redirect-area">
                                    <div className="check-container">
                                        <FontAwesomeIcon icon={"check"} className='fa-2x' />
                                        <div className="lds-ring">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                    {process.env.REACT_APP_MODE === 'consent' ? (
                                        <p>
                                            {polyglot.t('authorization.authorizationSuccessConsent')}.
                                            <br />
                                            {polyglot.t('authorization.redirecting')}{' '}
                                            <strong>{displayName}</strong>.
                                        </p>

                                    ) : (
                                        <p>
                                            {polyglot.t('authorization.authorizationSuccess')}.{' '}
                                            {polyglot.t('authorization.redirecting')}{' '}
                                            <strong>{displayName}</strong>.
                                        </p>
                                    )}
                                    <div style={{ opacity: 0.8, fontSize: '16px' }}>
                                        <span>
                                            {polyglot.t('authorization.onRedirectFail')}{' '}
                                            <a href={redirectURL}>{polyglot.t('authorization.here')}</a>
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>

                </>
            )}
        </LanguageConsumer>
    )
}