let replacements = {};
replacements["ά"] = "α";
replacements["ό"] = "ο";
replacements["έ"] = "ε";
replacements["ύ"] = "υ";
replacements["ϋ"] = "υ";
replacements["ή"] = "η";
replacements["ί"] = "ι";
replacements["ϊ"] = "ι";
replacements["ώ"] = "ω";
replacements["ς"] = "σ";
replacements["\\\\"] = "\\\\";


const normalizeString = (string) => {
    let normalized = string
    for (let [key, value] of Object.entries(replacements)) {
        normalized = normalized.replace(new RegExp(key, "g"), value)
    }
    return normalized
}

const isSuccessStatus = (status) => {
    const successValues = ['1200', '1201', '1202'];
    if(successValues.includes(status)){
      return true
    }
    return false 
  }
  
const formatScopes = (scopes) => {
    let result = "";
    for (let i = 0; i < scopes.length - 1; i++) {
        result += scopes[i] + ", "
    }
    result += scopes[scopes.length - 1]
    return result
}


const formatMobileNumber = (mobileNumber) => {
    try {
        if(  mobileNumber.length === 14){
            let countryCode = mobileNumber.substring(0,4)
            if (countryCode === '0030') {
                return mobileNumber.substring(4,14)
            }
        }
        return  mobileNumber
    } catch (error) {
        console.log(error)
        console.log(mobileNumber)
        return "-"
    }
}
  
 
export { formatMobileNumber }
export { formatScopes }
export { isSuccessStatus }
export { normalizeString }
