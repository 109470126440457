import React from 'react' 


export default(props) => (
  <div id='Footer'>
    <div className='pull-left'>
      <div className='footerLinks'>
        <ul className='footerList'>
          {process.env.REACT_APP_MODE === 'consent' ? (
            <>
              <li><a target="_blank" href="https://www.gsis.gr/Service-for-the-supply-of-electricity-and-gas" rel="noopener noreferrer">Συχνές Ερωτήσεις</a></li>
              <li><a target="_blank" href="https://www.gov.gr/info/politiki-aporritou" rel="noopener noreferrer">Πολιτική Απορρήτου</a></li>
              <li><a target="_blank" href="https://support.gov.gr/guide/?type=govgr275" rel="noopener noreferrer">Επικοινωνία</a></li>
            </>
          ) : (
            <>
              <li><a target="_blank" href="https://www.gsis.gr/egov-KYC/FAQs_KYC" rel="noopener noreferrer">Συχνές Ερωτήσεις</a></li>
              <li><a target="_blank" href="https://www.gov.gr/info/politiki-aporritou" rel="noopener noreferrer">Πολιτική Απορρήτου</a></li>
              <li><a target="_blank" href="https://www.gsis.gr/sites/default/files/KYC/Termsofuse_KYC.pdf" rel="noopener noreferrer">Όροι Χρήσης</a></li>
              <li><a target="_blank" href="https://support.gov.gr/guide/index.jsp?type=govgr05" rel="noopener noreferrer">Επικοινωνία</a></li>
            </>
            )
          }
        </ul>
      </div>
    </div>
    <div className='pull-right'>
      <span>
        <img id='ggps-logo' src='/ggps.png' alt='ggps.png'/>
      </span>
      <span>
        <img id='ministry-logo' src='/ministry.png' alt='ministry.png'/>
      </span>
    </div>
  </div>
)