import React from 'react';
import Login from './Login'
import { AuthConsumer } from "./lib/AuthContext"
import Redirect from './components/Redirect'
import { LanguageConsumer } from './lib/LanguageContext'
import Header from './Header'
import PageTitle from './components/PageTitle';

export default (props) => {

  const next = props.location && props.location.state && props.location.state.next ? props.location.state.next : ''
  const title = 'eGov KYC - Αρχική'

  let serviceLongName = <>Η υπηρεσία <strong>"Συστηθείτε" (eGov KYC- Know Your Customer)</strong></>

  if (process.env.REACT_APP_MODE === 'consent') {
    serviceLongName = <>Η <strong>Υπηρεσία Διάθεσης Στοιχείων μέσω της Ενιαίας Ψηφιακής Πύλης της Δημόσιας Διοίκησης</strong></>
  }

  return (
    <React.Fragment>
      <Header disableNav={true} disableLogout={true}/>
      <PageTitle title={title}/>
      <AuthConsumer>
        {({ loggedIn, loading }) => (
            <LanguageConsumer>
              {({ polyglot }) => (
                <React.Fragment>
                  {loggedIn && <Redirect from="/" to="mydata" noThrow />}
                  {!loggedIn && (
                    <React.Fragment>
                      <div id='Home'>
                        <div className='main container'>
                          <p>{serviceLongName} προσφέρει μια ψηφιακή εναλλακτική στην προσκόμιση εγγράφων του δημοσίου, για τη βεβαίωση στοιχείων του πολίτη σε φορείς του ιδιωτικού τομέα εφόσον απαιτείται από τη νομοθεσία.
                          </p>
                          <p>Τα στοιχεία αντλούνται από τα πρωτογενή πληροφοριακά συστήματα του Δημοσίου χωρίς να αποθηκεύονται στην υπηρεσία και η διάθεσή τους προϋποθέτει κάθε φορά τη ρητή συγκατάθεση του πολίτη. <br/></p>
                          <Login next={next} />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>)}
            </LanguageConsumer>
        )}
      </AuthConsumer>
    </React.Fragment>)
}